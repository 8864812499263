import axios from "axios";
import CryptoJS from "crypto-js";
import { getToken } from "../common/LocalStorageService";
import { showErrorMessage } from "../common/notification";
import { logOutRedirectCall } from "../common/RedirectPathManage";

var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY);
var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV);

const axiosClient = axios.create({
  // baseURL:
    // "https://8f75-150-129-104-1.ngrok-free.app/" ||
    // "https://codersoul.hyperlinkdevteam.link:8061/" ||
    // process.env.REACT_APP_BASE_URL,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "api-key": process.env.REACT_APP_API_KEY,
    "Accept-Language":
      localStorage.getItem("selectedLanguage") === "FR"
        ? "fr"
        : localStorage.getItem("selectedLanguage") === "PT"
        ? "port"
        : "en",
    "Content-Type": "text/plain",
  },
});

// Body Encryption Request
axiosClient.interceptors.request.use(function (request) {
  
  request.data = bodyEncryption(request.data, true);
  const token = getToken();
  if (token) {
    request.headers["token"] = bodyEncryption(token);
  }

  request.headers["Accept-Language"] =
    localStorage.getItem("selectedLanguage") === "FR"
      ? "fr"
      : localStorage.getItem("selectedLanguage") === "PT"
      ? "port"
      : "en";
  return request;
});

axiosClient.interceptors.response.use(
  function (response) {
    response = bodyDecryption(response.data);

    if (response.code === 400) {
      showErrorMessage(response.data.message);
    }
    

    return response;
  },

  function (error) {
    
    let res = error.response;
    if (!res) {
      return Promise.reject(error);
    }

    if (res.status === 401) {
      logOutRedirectCall();
      const response = bodyDecryption(res.data);
      return response;
    } else if (res.status === "0" || res.status === "2") {
      const response = bodyDecryption(res.data);
      return response;
    } else {
      console.error(
        "Looks like there was a problem. Status Code: " + res.status
      );
      return Promise.reject(error);
    }
  }
);

function bodyEncryption(request, isStringify) {
  var request_ = isStringify ? JSON.stringify(request) : request;
  var encrypted = CryptoJS.AES.encrypt(request_, key, { iv: iv });
  return encrypted.toString();
}

function bodyDecryption(request) {
  var decrypted = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv });
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}

export { axiosClient };
