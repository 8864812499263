import React from "react";

const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));

const Dashboard = React.lazy(() => import("../pages/dashboard"));

const MyProfile = React.lazy(() => import("../pages/Profile"));

const Students = React.lazy(() => import("../pages/Student/index"));
const AddEditViewStudent = React.lazy(() =>
  import("../pages/Student/AddEditViewStudent")
);

const Tutors = React.lazy(() => import("../pages/Tutor/index"));
const AddEditViewTutor = React.lazy(() =>
  import("../pages/Tutor/AddEditViewTutor")
);

const Semester = React.lazy(() => import("../pages/Semesters"));
const AddEditViewSemester = React.lazy(() =>
  import("../pages/Semesters/AddEditViewSemester")
);

const University = React.lazy(() => import("../pages/University"));
const AddEditViewUniversity = React.lazy(() =>
  import("../pages/University/AddEditViewUniversity")
);

const CMSpages = React.lazy(() => import("../pages/CMS"));
const AddViewEditCMS = React.lazy(() => import("../pages/CMS/AddViewEditCMS"));

const Settingspages = React.lazy(() => import("../pages/Settings"));
const ViewEditSetting = React.lazy(() =>
  import("../pages/Settings/EditViewSetting")
);

const BlogPages = React.lazy(() => import("../pages/Blog"));
const AddViewEditBlog = React.lazy(() =>
  import("../pages/Blog/AddViewEditBlog")
);

const FAQPages = React.lazy(() => import("../pages/FAQ"));
const AddViewEditFAQ = React.lazy(() => import("../pages/FAQ/AddViewEditFaq"));

const ContactUsPage = React.lazy(() => import("../pages/ContactUs"));
const ViewContactUsPage = React.lazy(() =>
  import("../pages/ContactUs/ViewContactus")
);

const BookingPage = React.lazy(() => import("../pages/Bookings"));
const AddEditViewBookings = React.lazy(() =>
  import("../pages/Bookings/AddEditViewBookings")
);

const CancelBookingRequestPage = React.lazy(() =>
  import("../pages/CancelBookingRequest")
);

const ReviewPage = React.lazy(() => import("../pages/Reviews"));
const ViewEditReviewPage = React.lazy(() =>
  import("../pages/Reviews/AddEditViewReviews")
);

const DiscountPage = React.lazy(() => import("../pages/Discount"));
const AddEditViewDiscount = React.lazy(() =>
  import("../pages/Discount/AddEditViewDiscount")
);

const CoursesPage = React.lazy(() => import("../pages/Courses"));
const AddEditViewCourses = React.lazy(() =>
  import("../pages/Courses/AddEditViewCourse")
);
const CoursesBookingPage = React.lazy(() =>
  import("../pages/Courses/CourseBookingList")
);
const CoursesSlotPage = React.lazy(() =>
  import("../pages/Courses/CourseSlotList")
);

const IndividualEarningList = React.lazy(() =>
  import("../pages/Earning/IndividualEarning")
);
const CourseEarningList = React.lazy(() =>
  import("../pages/Earning/CourseEarning")
);
const ReportList = React.lazy(() => import("../pages/Reports"));

const NotificationList = React.lazy(() => import("../pages/Notification"));
const SendNotification = React.lazy(() =>
  import("../pages/Notification/SendNotification")
);

const IndividualWeeklySettlement = React.lazy(() =>
  import("../pages/WeeklySettlement/IndividualWeeklySettlement")
);
const GroupWeeklySettlement = React.lazy(() =>
  import("../pages/WeeklySettlement/GroupWeeklySettlement")
);

const ManageTutorPosition = React.lazy(() =>
  import("../pages/ManagePosition/ManageTutorPosition")
);

const publicRouteList = [
  {
    path: "/",
    name: "Login",
    element: <Login />,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
  },
];

const privateRouteList = [
  {
    path: "/dashboard",
    name: "Dashboards",
    element: <Dashboard />,
  },
  {
    path: "/reports",
    name: "Repoprts",
    element: <ReportList />,
  },
  {
    path: "/student",
    name: "Student",
    element: <Students />,
  },
  {
    path: "/my-profile",
    name: "My Profile",
    element: <MyProfile />,
  },
  {
    path: "/student/edit/:id",
    name: "Student",
    element: <AddEditViewStudent type="edit" />,
  },
  {
    path: "/student/view/:id",
    name: "Student",
    element: <AddEditViewStudent type="view" />,
  },
  {
    path: "/tutors",
    name: "Tutors",
    element: <Tutors />,
  },
  {
    path: "/manage-tutor-position",
    name: "Tutors",
    element: <ManageTutorPosition />,
  },
  {
    path: "/tutor/edit/:id",
    name: "Tutors",
    element: <AddEditViewTutor type="edit" />,
  },
  {
    path: "/tutor/view/:id",
    name: "Tutors",
    element: <AddEditViewTutor type="view" />,
  },
  {
    path: "/university",
    name: "University",
    element: <University />,
  },
  {
    path: "/university/add",
    name: "University Add",
    element: <AddEditViewUniversity type="add" />,
  },
  {
    path: "/university/edit/:id",
    name: "University Edit",
    element: <AddEditViewUniversity type="edit" />,
  },
  {
    path: "/university/view/:id",
    name: "University View",
    element: <AddEditViewUniversity type="view" />,
  },
  {
    path: "/semester",
    name: "Semester",
    element: <Semester />,
  },
  {
    path: "/semester/add",
    name: "Semester",
    element: <AddEditViewSemester type="add" />,
  },
  {
    path: "/semester/edit/:id",
    name: "Semester",
    element: <AddEditViewSemester type="edit" />,
  },
  {
    path: "/semester/view/:id",
    name: "Semester",
    element: <AddEditViewSemester type="view" />,
  },
  {
    path: "/cms",
    name: "CMS",
    element: <CMSpages />,
  },
  {
    path: "/settings",
    name: "Settings",
    element: <Settingspages />,
  },
  {
    path: "/settings/view/:id",
    name: "View Settings",
    element: <ViewEditSetting type="view" />,
  },
  {
    path: "/settings/edit/:id",
    name: "edit Settings",
    element: <ViewEditSetting type="edit" />,
  },
  {
    path: "/cms/add",
    name: "CMS",
    element: <AddViewEditCMS type="add" />,
  },
  {
    path: "/cms/edit/:id",
    name: "CMS",
    element: <AddViewEditCMS type="edit" />,
  },
  {
    path: "/cms/view/:id",
    name: "CMS",
    element: <AddViewEditCMS type="view" />,
  },
  {
    path: "/blog",
    name: "Blog",
    element: <BlogPages />,
  },
  {
    path: "/blog/add",
    name: "Blog",
    element: <AddViewEditBlog type="add" />,
  },
  {
    path: "/blog/edit/:id",
    name: "Blog",
    element: <AddViewEditBlog type="edit" />,
  },
  {
    path: "/blog/view/:id",
    name: "Blog",
    element: <AddViewEditBlog type="view" />,
  },
  {
    path: "/faq",
    name: "FAQ",
    element: <FAQPages />,
  },
  {
    path: "/faq/add",
    name: "FAQ",
    element: <AddViewEditFAQ type="add" />,
  },
  {
    path: "/faq/edit/:id",
    name: "FAQ",
    element: <AddViewEditFAQ type="edit" />,
  },
  {
    path: "/faq/view/:id",
    name: "FAQ",
    element: <AddViewEditFAQ type="view" />,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    element: <ContactUsPage />,
  },
  {
    path: "/contact-us/view/:id",
    name: "Contact Us",
    element: <ViewContactUsPage type="view" />,
  },
  {
    path: "/discount",
    name: "Discount",
    element: <DiscountPage />,
  },
  {
    path: "/discount/view/:id",
    name: "Discount",
    element: <AddEditViewDiscount type="view" />,
  },
  {
    path: "/discount/edit/:id",
    name: "Discount",
    element: <AddEditViewDiscount type="edit" />,
  },
  {
    path: "/discount/add",
    name: "Discount",
    element: <AddEditViewDiscount type="add" />,
  },
  {
    path: "/individual-bookings",
    name: "Bookings",
    element: <BookingPage />,
  },
  {
    path: "/bookings/view/:id",
    name: "Bookings",
    element: <AddEditViewBookings type="view" />,
  },
  {
    path: "/bookings/cancel-request",
    name: "Bookings",
    element: <CancelBookingRequestPage type="view" />,
  },
  {
    path: "/reviews",
    name: "Reviews",
    element: <ReviewPage />,
  },
  {
    path: "/reviews/edit/:id",
    name: "Reviews",
    element: <ViewEditReviewPage type="edit" />,
  },
  {
    path: "/reviews/view/:id",
    name: "Reviews",
    element: <ViewEditReviewPage type="view" />,
  },
  {
    path: "/courses",
    name: "Courses",
    element: <CoursesPage />,
  },
  {
    path: "/courses/add",
    name: "Add Courses",
    element: <AddEditViewCourses type="add" />,
  },
  {
    path: "/courses/edit/:id",
    name: "Edit Courses",
    element: <AddEditViewCourses type="edit" />,
  },
  {
    path: "/courses/view/:id",
    name: "View Courses",
    element: <AddEditViewCourses type="view" />,
  },
  {
    path: "/courses/booking/:id",
    name: "Edit Courses",
    element: <CoursesBookingPage />,
  },
  {
    path: "/courses/slots/:id",
    name: "Edit Courses",
    element: <CoursesSlotPage />,
  },
  {
    path: "/individual-earning",
    name: "Earning",
    element: <IndividualEarningList />,
  },
  {
    path: "/course-earning",
    name: "Earning",
    element: <CourseEarningList />,
  },
  {
    path: "/notification",
    name: "Notification",
    element: <NotificationList />,
  },
  {
    path: "/notification/send",
    name: "Notification Send",
    element: <SendNotification />,
  },
  {
    path: "/individual-weekly-settlement",
    name: "Individual Weekly Settlement",
    element: <IndividualWeeklySettlement />,
  },
  {
    path: "/group-weekly-settlement",
    name: "group Weekly Settlement",
    element: <GroupWeeklySettlement />,
  },
];
export { publicRouteList, privateRouteList };
