import { axiosClient } from "./apiClient";

//============================================================================//
//============================ user api start=================================//
//============================================================================//

export function login(data) {
  return axiosClient.post("/api/v1/admin/admin_signin", data);
}

export function studentList(data) {
  return axiosClient.post("/api/v1/admin/student_list", data);
}

export function getAllDiscountList(data) {
  return axiosClient.post("/api/v1/admin/GetDiscountCodes", data);
}

export function getAllBookingList(data) {
  return axiosClient.post("/api/v1/admin/GetBookingList", data);
}

export function userWiseGetCourseBooking(data) {
  return axiosClient.post("/api/v1/admin/userWiseGetCourseBooking", data);
}

export function getAllCancelRequestList(data) {
  return axiosClient.post("/api/v1/admin/cancelRequestList", data);
}

export function getAllEarningList(data) {
  return axiosClient.post("/api/v1/admin/adminEarning", data);
}

export function getAllNotificationList(data) {
  return axiosClient.post("/api/v1/admin/notificationList", data);
}

export function AcceptRejectCancelRequestAdmin(data) {
  return axiosClient.post("/api/v1/admin/AcceptRejectCancelRequestAdmin", data);
}

export function GetAllRatingList(data) {
  return axiosClient.post("/api/v1/admin/GetRatingList", data);
}

export function approveRejectReviews(data) {
  return axiosClient.post("/api/v1/admin/approve_reject_rating", data);
}

export function updateDiscount(data) {
  return axiosClient.post("/api/v1/admin/updateDiscount", data);
}

export function addDiscountCodes(data) {
  return axiosClient.post("/api/v1/admin/addDiscountCodes", data);
}

export function tutorList(data) {
  return axiosClient.post("/api/v1/admin/tutor_list", data);
}

export function allUserList(data) {
  return axiosClient.post("/api/v1/admin/user_list", data);
}

export function getCountry(data) {
  return axiosClient.post("/api/v1/website/getCountryList", data);
}

export function getState(data) {
  return axiosClient.post("/api/v1/website/getStateList", data);
}

export function activeInactiveDeleteUser(data) {
  return axiosClient.post("/api/v1/admin/inactive_delete_user", data);
}

export function acceptRejectCourses(data) {
  return axiosClient.post("/api/v1/admin/acceptRejectCourses", data);
}

export function coursePermission(data) {
  return axiosClient.post("/api/v1/admin/course_permission", data);
}

export function activeInactiveDeleteDiscount(data) {
  return axiosClient.post("/api/v1/admin/inactive_delete_discount", data);
}

export function getUniversityList(data) {
  // Master
  return axiosClient.post("/api/v1/auth/getUniversityList", data);
}

export function getSemester(data) {
  //  Master
  return axiosClient.post("/api/v1/auth/getSemesterList", data);
}
export function getSubject(data) {
  // Master
  return axiosClient.post("/api/v1/auth/getSubjectsList", data);
}

export function getAllUniversityList(data) {
  return axiosClient.post("/api/v1/admin/getUniversityList", data);
}

export function activeInactiveDeleteUniversity(data) {
  return axiosClient.post("/api/v1/admin/inactive_delete_university", data);
}

export function getAllSemesterList(data) {
  return axiosClient.post("/api/v1/admin/getSemesterList", data);
}

export function addUniversity(data) {
  return axiosClient.post("/api/v1/admin/add_university", data);
}

export function updateUniversity(data) {
  return axiosClient.post("/api/v1/admin/update_university", data);
}

export function addSemester(data) {
  return axiosClient.post("/api/v1/admin/add_semester", data);
}

export function updateSemester(data) {
  return axiosClient.post("/api/v1/admin/update_semester", data);
}

export function activeInactiveDeleteSemester(data) {
  return axiosClient.post("/api/v1/admin/inactive_delete_semester", data);
}

export function editTutorProfile(data) {
  return axiosClient.post("/api/v1/admin/tutor_edit_profile", data);
}

export function updateUserData(data) {
  return axiosClient.post("/api/v1/admin/update_user_data", data);
}


export function editAdminProfile(data) {
  return axiosClient.post("/api/v1/admin/update_admin", data);
}

export function getBlog(data) {
  return axiosClient.post("/api/v1/admin/get_blogs", data);
}

export function addBlog(data) {
  return axiosClient.post("/api/v1/admin/add_blogs", data);
}

export function updateBlog(data) {
  return axiosClient.post("/api/v1/admin/update_blogs", data);
}

export function getFAQ(data) {
  return axiosClient.post("/api/v1/admin/get_faqs", data);
}

export function addFAQ(data) {
  return axiosClient.post("/api/v1/admin/add_faqs", data);
}

export function updateFAQ(data) {
  return axiosClient.post("/api/v1/admin/update_faqs", data);
}

export function getCMS(data) {
  return axiosClient.post("/api/v1/admin/getCmsPage", data);
}

export function GetSettings(data) {
  return axiosClient.post("/api/v1/admin/GetSettings", data);
}
export function updateSettings(data) {
  return axiosClient.post("/api/v1/admin/update_settings", data);
}

export function updateCMS(data) {
  return axiosClient.post("/api/v1/admin/updateCmsPage", data);
}

export function getContactUs(data) {
  return axiosClient.post("/api/v1/admin/get_contact_us", data);
}

export function activeInactiveDeleteContactUs(data) {
  return axiosClient.post("/api/v1/admin/inactive_delete_contact_us", data);
}

export function getLanguage(data) {
  return axiosClient.post("/api/v1/website/languageList", data);
}

export function getCourses(data) {
  return axiosClient.post("/api/v1/tutor/GetCourses", data);
}

export function deleteCourses(data) {
  return axiosClient.post("/api/v1/admin/deleteCourses", data);
}

export function getCourseBookingLists(data) {
  return axiosClient.post("/api/v1/admin/getCourseBookingLists", data);
}

export function coursesSloatsAdmin(data) {
  return axiosClient.post("/api/v1/admin/coursesSloatsAdmin", data);
}

export function addCourse(data) {
  return axiosClient.post("/api/v1/tutor/addCourses", data);
}

export function editReview(data) {
  return axiosClient.post("/api/v1/admin/editReview", data);
}

export function updateCourses(data) {
  return axiosClient.post("/api/v1/tutor/updateCourses", data);
}

export function adminSendNotification(data) {
  return axiosClient.post("/api/v1/admin/admin_send_push", data);
}

export function individualWeeklySettlmentsListing(data) {
  return axiosClient.post("/api/v1/admin/weeklySettlmentsListing", data);
}

export function groupWeeklySettlmentsListing(data) {
  return axiosClient.post("/api/v1/admin/coursesWeeklySettlmentsListing", data);
}

export function adminDashboard(data) {
  return axiosClient.post("/api/v1/admin/adminDashboard", data);
}

export function createChatRoom(data) {
  return axiosClient.post("/api/v1/admin/createChatRoom", data);
}

export function deleteSubjectsUniversity(data) {
  return axiosClient.post("/api/v1/admin/delete_subjects_university", data);
}

export function deleteSemesterUniversity(data) {
  return axiosClient.post("/api/v1/admin/delete_semester_university", data);
}

export function setUsersPositions(data) {
  return axiosClient.post("/api/v1/admin/setUsersPositions", data);
}

export function setBlogsPositions(data) {
  return axiosClient.post("/api/v1/admin/setBlogsPositions", data);
}

export function addRemoveGroupUsers(data) {
  return axiosClient.post("/api/v1/admin/addRemoveGroupUsers", data);
}

export function getStudentReports(data) {
  return axiosClient.post("/api/v1/admin/getStudentReports", data);
}

export function getTutorReports(data) {
  return axiosClient.post("/api/v1/admin/getTutorReports", data);
}

export function getCourseBookingReports(data) {
  return axiosClient.post("/api/v1/admin/getBookingCourseReports", data);
}

export function getIndividualBookingReports(data) {
  return axiosClient.post("/api/v1/admin/getPrivetBookingReports", data);
}

export function getUniversityReports(data) {
  return axiosClient.post("/api/v1/admin/getUniversityBookingReports", data);
}
