import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";

import layoutSlices from "./slices/layout/layoutSlices";

export const store = configureStore({
  reducer: {
    Layout: layoutSlices,
    auth: authSlice,
  },
});
