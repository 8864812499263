import { createSlice } from "@reduxjs/toolkit";
import {
  LayoutMode,
  LayoutTypes,
  LayoutColor,
  LayoutWidth,
  MenuPositions,
  SideBarTheme,
  SideBarTypes,
  TopbarTheme,
  TwoColumnTheme,
} from "../../../constants/layout";
import { getLayoutConfigs } from "../../../utils";

const initialState = {
  twoColumnTheme: TwoColumnTheme.TWOCOLUMN_LIGHT,
  layoutMode: LayoutMode.LAYOUT_DEFAULT,
  sidenavUser: false,
  layoutType: LayoutTypes.LAYOUT_VERTICAL,
  layoutColor: LayoutColor.LAYOUT_COLOR_LIGHT,
  layoutWidth: LayoutWidth.LAYOUT_WIDTH_FLUID,
  menuPosition: MenuPositions.MENU_POSITION_FIXED,
  leftSideBarTheme: SideBarTheme.LEFT_SIDEBAR_THEME_LIGHT,
  leftSideBarType: SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT,
  showTwoToneIcons: false,
  showSidebarUserInfo: false,
  topbarTheme: TopbarTheme.TOPBAR_THEME_LIGHT,
  isOpenRightSideBar: false,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    changeTwoColumnTheme: (state, action) => {
      state.twoColumnTheme = action.payload;
    },
    changeLayout: (state, action) => {
      state.layoutType = action.payload;
    },
    changeLayoutMode: (state, action) => {
      state.layoutMode = action.payload;
    },
    changeLayoutColor: (state, action) => {
      state.layoutColor = action.payload;
    },
    changeLayoutWidth: (state, action) => {
      const layoutConfig = getLayoutConfigs(action.payload);
      return {
        ...state,
        layoutWidth: action.payload,
        ...layoutConfig,
      };
    },
    changeMenuPosition: (state, action) => {
      state.menuPosition = action.payload;
    },
    changeSidebarTheme: (state, action) => {
      state.leftSideBarTheme = action.payload;
    },
    changeSidebarType: (state, action) => {
      state.leftSideBarType = action.payload;
    },
    toggleSidebarUserInfo: (state, action) => {
      state.showSidebarUserInfo = action.payload;
    },
    changeTopbarTheme: (state, action) => {
      state.topbarTheme = action.payload;
    },
    toggleTwoToneIcons: (state, action) => {
      state.showTwoToneIcons = action.payload;
    },
    showRightSidebar: (state) => {
      state.isOpenRightSideBar = true;
    },
    hideRightSidebar: (state) => {
      state.isOpenRightSideBar = false;
    },
  },
});

export const {
  changeTwoColumnTheme,
  changeLayout,
  changeLayoutMode,
  changeLayoutColor,
  changeLayoutWidth,
  changeMenuPosition,
  changeSidebarTheme,
  changeSidebarType,
  toggleSidebarUserInfo,
  changeTopbarTheme,
  toggleTwoToneIcons,
  showRightSidebar,
  hideRightSidebar,
} = layoutSlice.actions;

export default layoutSlice.reducer;
