// AuthService.js

// Function to save user data to local storage
export const saveUserDataToLocal = (adminData) => {
  localStorage.setItem("adminData", btoa(JSON.stringify(adminData)));
};

// Function to get user data from local storage
export const getUserDataFromLocal = () => {
  const userDataString = localStorage.getItem("adminData");
  return userDataString ? JSON.parse(atob(userDataString)) : null;
};

// Function to remove user data from local storage
export const removeUserDataFromLocal = () => {
  localStorage.removeItem("adminData");
};

// Function to check if the user is authenticated
export const isAuthenticated = () => {
  const adminData = getUserDataFromLocal();
  return adminData && adminData.token;
};

// Function to get the user token
export const getToken = () => {
  const adminData = getUserDataFromLocal();
  
  return adminData ? adminData.token : null;
};

export const isAdmin = () => {
  const adminData = getUserDataFromLocal();
  return adminData && adminData.role === "admin";
};

export const isSubadmin = () => {
  const adminData = getUserDataFromLocal();
  return adminData && adminData.role === "subadmin";
};

export const hasPermissionForModule = (moduleName) => {
  const adminData = getUserDataFromLocal();
  const permission =
    adminData?.permissions &&
    adminData?.permissions?.find((perm) => perm.name === moduleName);
  return (
    isAdmin() ||
    (permission &&
      (permission.is_add ||
        permission.is_view ||
        permission.is_edit ||
        permission.is_remove))
  );
};

export const getUserPermissions = (moduleName) => {
  const adminData = getUserDataFromLocal();
  
  const permission =
    adminData?.permissions &&
    adminData?.permissions?.find((perm) => perm.name === moduleName);
  return permission || {};
};
