import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showSuccessMessage = (msg, config = {}) => {
  toast.success(msg || "Success", { ...config });
};
export const showErrorMessage = (msg, config = {}) => {
  toast.error(msg || "alert", { ...config });
};

export const showPromiseNotification = (promise, msg, config = {}) => {
  toast.promise(promise, {
    pending: "Please wait...",
    success: {
      render({ data }) {
        return data?.message;
      },
      icon: "🟢",
    },
    error: {
      render({ data }) {
        return data?.message;
      },
      icon: "🟢",
    },
  });
};

const Notification = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

export default Notification;
