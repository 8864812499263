import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editAdminProfile, login } from "../../apis/apiHandler";
import {
  getToken,
  getUserDataFromLocal,
  removeUserDataFromLocal,
  saveUserDataToLocal,
} from "../../common/LocalStorageService";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await login(payload);
      
      if (response?.code === "1") {
        saveUserDataToLocal(response.data);
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "auth/updateAdmin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await editAdminProfile(payload);
      
      if (response?.code === "1") {
        saveUserDataToLocal(response.data);
        return response;
      } else {
        return rejectWithValue(response);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const userToken = getToken();

const initialState = {
  loading: false,
  userInfo: getUserDataFromLocal(),
  userToken,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    logout: (state) => {
      removeUserDataFromLocal(); // deletes token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.userInfo = payload.data;
        state.userToken = payload.data.password;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdmin.fulfilled, (state, { payload }) => {
        
        state.loading = false;
        state.userInfo = payload.data;
        state.userToken = payload.data.password;
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
