import React from "react";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";

import { privateRouteList, publicRouteList } from "./index";

import PublicLayouts from "./PublicLayout";
import PrivateLayout from "./PrivateLayout";
const Logout = React.lazy(() => import("../pages/auth/Logout"));

const AllRoutes = (props) => {
  const { layout } = useSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    return VerticalLayout;
  };

  const Layout = getLayout();

  const routes = [
    {
      path: "/auth/logout",
      name: "Logout",
      element: <DefaultLayout {...props} layout={layout} />,
      children: [
        {
          path: "",
          name: "Logout",
          element: <Logout />,
        },
      ],
    },

    {
      path: "/",
      element: <PublicLayouts />,
      children: [
        {
          path: "/",
          element: <DefaultLayout {...props} layout={layout} />,
          children: publicRouteList,
        },
      ],
    },

    {
      path: "/",
      element: <PrivateLayout />,
      children: [
        {
          path: "/",
          element: <Layout {...props} />,
          children: privateRouteList,
        },
      ],
    },
  ];

  const routing = useRoutes(routes);

  return <>{routing}</>;
};

export default AllRoutes;
