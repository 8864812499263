import React, { useEffect, Suspense } from "react";
import { useSelector } from "react-redux";

// utils
import { changeHTMLAttribute } from "../utils";
import { Outlet } from "react-router-dom";

const loading = () => <div className=""></div>;

const DefaultLayout = (props) => {
  const { layoutColor } = useSelector((state) => ({
    layoutColor: state.Layout.layoutColor,
  }));

  useEffect(() => {
    changeHTMLAttribute("data-bs-theme", layoutColor);
  }, [layoutColor]);

  useEffect(() => {
    if (document.body)
      document.body.classList.add(
        "authentication-bg",
        "authentication-bg-pattern"
      );

    return () => {
      if (document.body)
        document.body.classList.remove(
          "authentication-bg",
          "authentication-bg-pattern"
        );
    };
  }, []);

  return (
    <>
      <Suspense fallback={loading()}>
        <Outlet />
      </Suspense>
    </>
  );
};
export default DefaultLayout;
