/* Layout types and other constants */

export const LayoutTypes = {
  LAYOUT_VERTICAL: "vertical",
  LAYOUT_HORIZONTAL: "horizontal",
  LAYOUT_DETACHED: "detached",
  LAYOUT_TWO_COLUMN: "two-column",
};

export const LayoutMode = {
  LAYOUT_DEFAULT: "fluid",
  LAYOUT_DETACHED: "detached",
};

export const TwoColumnTheme = {
  TWOCOLUMN_LIGHT: "light",
  TWOCOLUMN_DARK: "dark",
  TWOCOLUMN_BRAND: "brand",
  TWOCOLUMN_GRADIENT: "gradient",
};

export const LayoutColor = {
  LAYOUT_COLOR_LIGHT: "light",
  LAYOUT_COLOR_DARK: "dark",
};

export const LayoutWidth = {
  LAYOUT_WIDTH_FLUID: "fluid",
  LAYOUT_WIDTH_BOXED: "boxed",
};

export const MenuPositions = {
  MENU_POSITION_FIXED: "fixed",
  MENU_POSITION_SCROLLABLE: "scrollable",
};

export const SideBarTheme = {
  LEFT_SIDEBAR_THEME_LIGHT: "light",
  LEFT_SIDEBAR_THEME_DARK: "dark",
  LEFT_SIDEBAR_THEME_BRAND: "brand",
  LEFT_SIDEBAR_THEME_GRADIENT: "gradient",
};

export const SideBarTypes = {
  LEFT_SIDEBAR_TYPE_DEFAULT: "default",
  LEFT_SIDEBAR_TYPE_CONDENSED: "condensed",
  LEFT_SIDEBAR_TYPE_COMPACT: "compact",
  LEFT_SIDEBAR_TYPE_FULL: "full",
  LEFT_SIDEBAR_TYPE_FULLSCREEN: "fullscreen",
};

export const TopbarTheme = {
  TOPBAR_THEME_LIGHT: "light",
  TOPBAR_THEME_DARK: "dark",
  TOPBAR_THEME_BRAND: "brand",
};
